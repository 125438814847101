import React from 'react';
import {
    FormRenderData,
    Module,
    ModalSize,
    FilterSelectorValuesParams,
    FieldFValue,
} from '@cuatroochenta/co-admin-react-library';
import './res/scss/custom-styles.scss'
import '@cuatroochenta/co-admin-react-library/lib/index.css';

export const App = ({}): React.ReactElement => {
    const beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    const afterList = (): React.ReactElement => {
        return <></>;
    };

    const afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    const getSizeFilterModal = (entity: string): ModalSize | undefined => {
        if (entity === 'user') {
            return ModalSize.LG;
        }
    };

    const filterSelectorValues = (
        filterSelectorValuesParams: FilterSelectorValuesParams
    ): FieldFValue[] => {
        return filterSelectorValuesParams.values;
    }

    return (
        <Module
            actions={[]}
            afterFormView={afterFormView}
            beforeFormView={beforeFormView}
            afterList={afterList}
            locale={'es'}
            getSizeFilterModal={getSizeFilterModal}
            filterSelectorValues={filterSelectorValues}
        />
    );

}
